<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">

    <Snackbar ref="snackbar"/>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialog.filter" persistent width="650">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #F05326;"
        >
          <span class="white--text">
            Filter
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="dialog.filter = false" color="white" :disabled="process.run">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            dense
            flat
            v-model="formFilter.order_id"
            label="Please Input Order ID"
            color="#F05326">
          </v-text-field>
          <v-text-field
            outlined
            dense
            flat
            v-model="formFilter.code"
            label="Please Input License Code"
            color="#F05326">
          </v-text-field>
          <v-autocomplete
            outlined
            dense
            flat
            v-model="formFilter.member_id"
            :items="member.list"
            label="Please Select Member"
            item-value="id"
            item-text="fullname"
            color="#F05326">
          </v-autocomplete>
          <v-autocomplete
            label="Please Select Merchant"
            outlined
            dense
            flat
            color="primary"
            no-data-text="please select company first"
            :loading="process.run"
            v-model="formFilter.merchant_id"
            :items="merchant.list"
            item-value="id"
            item-text="name"
            :search-input.sync="search_merchant"
            @keyup="searchMerchant"
          >
            <template v-slot:selection="data">
              <div class="text-capitalize">{{ data.item.name }}</div>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  class="text-capitalize"
                  v-html="data.item.name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-select
            outlined
            dense
            flat
            v-model="formFilter.is_used"
            :items="[{text: 'Used', value: 1}, {text: 'Unused', value: 0}]"
            label="Please Select Usage Status"
            color="#F05326">
          </v-select>
          <v-select
            outlined
            dense
            flat
            v-model="formFilter.is_sold"
            :items="[{text: 'Sold Out', value: 1}, {text: 'Unsold', value: 0}]"
            label=" Please Select Selling Status"
            color="#F05326">
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            depressed
            :loading="process.run"
            class="text-capitalize"
            @click="closeFilter('close')">
            Close
          </v-btn>
          <v-btn 
            depressed
            color="red"
            class="white--text text-capitalize"
            :loading="process.run"
            @click="closeFilter('reset')">
            Reset
          </v-btn>
          <v-btn 
            depressed
            color="#EF6C00" 
            class="white--text text-capitalize"
            :loading="process.run"
            @click="fetch()">
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

     <!-- DIALOG CHANGE MERCHANT -->
     <v-dialog v-model="dialog.change_merchant" scrollable persistent width="650">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #f05326"
        >
          <span class="white--text"> Change Merchant </span>
          <v-spacer></v-spacer>
          <v-icon
            @click="dialog.change_merchant = false"
            color="white"
            :disabled="process.run">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-autocomplete
                label="Please Select Company"
                outlined
                dense
                flat
                color="primary"
                hide-details="auto"
                no-data-text="no data found"
                :loading="process.run"
                v-model="form.company_id"
                item-value="id"
                item-text="name"
                :items="company.list"
                :search-input.sync="search_company"
                @keyup="searchCompany"
                @change="
                  fetchMerchant();
                "
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                label="Please Select Merchant"
                outlined
                dense
                flat
                color="primary"
                hide-details="auto"
                no-data-text="please select company first"
                :loading="process.run"
                v-model="form.merchant_id"
                :items="merchant.list"
                item-value="id"
                item-text="name"
                :search-input.sync="search_merchant"
                @keyup="searchMerchant"
              >
                <template v-slot:selection="data">
                  <div class="text-capitalize">{{ data.item.name }}</div>
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-capitalize"
                      v-html="data.item.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            :loading="process.run"
            class="text-capitalize"
            @click="dialog.change_merchant = false"
          >
            Close
          </v-btn>
          <v-btn
            depressed
            color="#F05326"
            class="white--text text-capitalize"
            :loading="process.run"
            @click="changeMerchant()"
          >
            Save Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG CONFIRMATION RESET LICENSE -->
    <v-dialog v-model="dialog.confirm" width="450">
      <v-card>
        <v-card-title>
          Warning !
          <v-spacer></v-spacer>
          <v-icon size="30" @click="dialog.confirm = false" color="red">
            mdi-close-circle-outline
          </v-icon>
        </v-card-title>
        <v-card-text class="subtitle-1 font-weight-bold">
          Are you sure you want to reset this license ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            class="text-capitalize"
            @click="dialog.confirm = false">
            Cancel
          </v-btn>
          <v-btn
            color="#F05326"
            elevation="0"
            class="white--text text-capitalize"
            @click="updateLicense(id)">
            Yes, Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-5">
      <div class="title black--text mb-2">List License</div>
      <v-row justify="space-between" class="mt-2">
        <v-col cols="4">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Search License . . ."
              autocomplete="off"
              v-model="searching"
              :readonly="process.run"
              clearable
              color="#F05326"
              @click:clear="searching = '';"
              prepend-inner-icon="mdi-magnify">
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="d-flex float-right">
            <v-btn 
              v-if="false"
              depressed
              :loading="process.run"
              @click="showDialog()"
              elevation="0"
              color="#F05326" 
              class="text-capitalize ml-5 white--text">
              Generate voucher
            </v-btn>
            <v-btn 
              depressed
              :loading="process.run"
              @click="filter"
              elevation="0"
              color="#F5F5F5" 
              class="black--text text-capitalize ml-5">
              Filter by
              <v-icon right>mdi-filter-variant</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table-tbody">
          <v-data-table
            class="table-list"
            hide-default-footer
            fixed-header
            :loading="tableLoading"
            :headers="headers"
            :items="items.list"
            :items-per-page.sync="items.limit"
            :page.sync="items.page"
            :server-items-length="items.total"
            item-key="id"
            :footer-props="{
              itemsPerPageText : 'Data per halaman',
              'items-per-page-options': [5,10, 20, 50, 100, 200, 350]
            }"
            >
            <template slot="footer" slot-scope="{ props }">
              <Pagination 
                @reload="fetch(page)"
                @changeLimit="limit = $event; fetch()" 
                @changePage="page = $event; fetch($event)" 
                :model="props"/>
            </template>

            <template slot="item" slot-scope="props">
              <tr class="mx-3 px-3 box-shadow">
                <td class="text-center white">
                  {{ props.item.order_id }}
                </td>
                <td class="text-center white">
                  {{ props.item.code }}
                </td>
                <td class="text-center white">
                  {{ props.item.merchant.name ? props.item.merchant.name : '-' }}
                </td>
                <td class="text-start white">
                  {{ props.item.program.title.substring(0, 40) }} . . .
                </td>
                <td class="text-start white">
                  {{ props.item.user.full_name }} <br />
                  {{ props.item.user.email }}
                </td>
                <td class="text-center white">
                  <v-row align="center" justify="center">
                    <v-tooltip bottom v-if="Object.keys(props.item.buyer).length > 0 && props.item.buyer.fullname">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip class="mb-2" v-on="on" v-bind="attrs" outlined color="green" small v-if="props.item.is_sold">
                          Already Sold : {{ props.item.buyer.sold_at | datetime }}
                        </v-chip>
                      </template>
                      <div>
                        Buyer Name : {{ props.item.buyer.fullname }}
                      </div>
                      <div>
                        Buyer Email : {{ props.item.buyer.email }}
                      </div>
                    </v-tooltip>
                    <v-chip class="mb-2" outlined color="green" small v-if="props.item.is_sold && !props.item.buyer.fullname || Object.keys(props.item.buyer).length < 1">
                      Already Sold : {{ props.item.buyer.sold_at | datetime }}
                    </v-chip>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-chip class="mb-2" outlined color="blue" small v-if="props.item.is_used">
                      Already Used : {{ props.item.user.used_at | datetime }}
                    </v-chip>
                  </v-row>
                </td>
                <td class="text-center white">
                  <v-menu
                    left
                    offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on">
                        mdi-dots-vertical-circle-outline
                      </v-icon>
                    </template>

                    <v-list dense  class="pa-0 rounded-lg">
                      <v-list-item @click="resetLisence(props.item.id)">
                        <v-list-item-icon class="mr-4">
                          <v-icon color="error">mdi-lock-reset</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Update License</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <!-- <v-divider></v-divider>
                      <v-list-item @click="change_merchant(props.item)">
                        <v-list-item-icon class="mr-4">
                          <v-icon color="green">mdi-store-edit-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Change Merchant</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item> -->
                    </v-list>
                  </v-menu>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="resetLisence(props.item.id)">
                        <v-icon>mdi-lock-reset</v-icon>
                      </v-btn>
                    </template>
                    <span>Reset License</span>
                  </v-tooltip> -->
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>

  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import GridTable from '@/components/GridTable'
import Pagination from '@/components/Pagination.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
export default {
    components: { GridTable, Pagination, Breadcrumbs, Snackbar },
  data() {
    return {
      search_merchant: null,

      tableLoading: false,
      title:"List Users",
      limit: 10,
      page: 1,
      selected: {
        pagination: 0
      },
      headers: [
        { text: 'Order ID',align: 'center',value: 'order_id',sortable: false },
        { text: 'License Code',align: 'center',value: 'code',sortable: false },
        { text: 'Merchant',align: 'center',value: 'merchant.name',sortable: false },
        { text: 'Program Name',align: 'start',value: 'program.title',sortable: false },
        { text: 'User Full Name',align: 'start',value: 'user.fullname',sortable: false },
        { text: 'Status',align: 'center',value: 'status',sortable: false },
        { text: 'Action',align: 'center',value: 'status',sortable: false },
      ],
      items:{},
      member:{},
      searching:"",
      process: {
        run: false
      },
      id: "",
      formFilter : {
        member_id: "",
        merchant_id: "",
        license: "",
        code: "",
        order_id: "",
        is_used: "",
        is_sold: ""
      },
      dialog: {
        filter: false,
        confirm: false,
        change_merchant: false,
      },
      form:{},
      order:{},
      program:{},
      company:{},
      merchant:{},
      form: {
        company_id: "",
        program_id: "",
        order_id: "",
        merchant_id: "",
        total: "",
      },
    }
  },
  watch:{
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    searching: _.debounce(function(newVal) {
      this.searching = newVal;
      this.fetch()
    }, 500),
  },
  created(){
    this.fetch()
  },
  mounted(){},
  methods:{
    changePage (event) {
      if (event === undefined) {
        this.selected.pagination = 0
      } 
    },
    async filter(){
      await this.fetchMember()
      await this.fetchMerchant()
      this.dialog.filter = true
      // this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    closeFilter(state) {
      this.dialog.filter = false
      if(state != 'close'){
        this.formFilter = {
          member_id: "",
          merchant_id: "",
          license: "",
          code: "",
          order_id: "",
          is_used: "",
          is_sold: ""
        }
        this.fetch()
      }
    },
    async fetch(paging){
      let _self = this;
      _self.process.run = true
        this.tableLoading = true

      await get(`license/v1/superuser/list_license`,{
        params:{
          limit: this.limit,
          page: paging,
          q: this.searching,
          user_id: this.formFilter.member_id,
          merchant_id: this.formFilter.merchant_id,
          code: this.formFilter.code,
          order_id: this.formFilter.order_id,
          is_used: this.formFilter.is_used,
          is_sold: this.formFilter.is_sold,
        }
      }).then(response => {
        let res = response.data
        this.items = res.data
        this.tableLoading = false
        this.process.run = false
        this.$store.state.process.run = false
        this.totalData = res.data.total
        this.closeFilter('close')
      })
    },
    async fetchMember(paging){
      let _self = this;
      _self.process.run = true

      await get(`users/v1/superuser/member`,{
        params:{
          limit: this.limit,
          page: paging,
          sort: "created_at",
          dir: "asc",
          q: this.searching
        }
      }).then(response => {
        let res = response.data
        this.member = res.data
        this.process.run = false
        this.$store.state.process.run = false
        this.totalData = res.data.total_data
      })
    },
    showDialog(){
      this.dialog_generate=true;
      this.fetchCompany();
    },
    async fetchCompany(paging){
      let _self = this;
      _self.process.run = true

      await get(`company/v1/superuser/list`).then(response => {
        let res = response.data
        this.company = res.data
        this.process.run = false
        this.$store.state.process.run = false
      })
    },
    async fetchOrder(paging){
      let _self = this;
      _self.process.run = true

      await get(`license/v1/superuser/list?program_id=${this.form.program_id}&company_id=${this.form.company_id}`).then(response => {
        let res = response.data
        this.order = res.data
        this.process.run = false
        this.$store.state.process.run = false
      })
    },
    async fetchProgram(){
      let _self = this;
      _self.process.run = true

      await get(`program/v1/superuser/list?company_id=${this.form.company_id}`).then(response => {
        let res = response.data
        this.program = res.data
        this.process.run = false
        this.$store.state.process.run = false
        this.fetchMerchant()
      })
    },
    async fetchMerchant(){
      let _self = this;
      _self.process.run = true

      await get(`merchant/v1/superuser/list`).then(response => {
        let res = response.data
        this.merchant = res.data
        this.process.run = false
        this.$store.state.process.run = false
      })
    },
    getGenerate() {
      let _self = this;
      _self.process.run = true
      post(`license/v1/superuser/generate`, {
        data: this.form
      }).then(response => {
        let res = response.data
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Berhasil Generate Voucher`);
          this.dialog_generate = false
          this.form = {}
          this.fetch()
        } else {
          this.$refs.snackbar.open("#D32F2F", `Gagal Generate Voucher`);
        }
        this.process.run = false
      })
      .catch(() => {
        this.process.run = false
      })
    },
    resetLisence(id){
      this.dialog.confirm = true
      this.id = id
    },
    async updateLicense(id) {
      this.process.run = true
      await put(`license/v1/superuser/update/${id}`, {
        data: {
          is_used: false
        }
      }).then(response => {
        let res = response.data
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Berhasil Reset Voucher`);
          this.fetch()
        } else {
          this.$refs.snackbar.open("#D32F2F", `Gagal Reset Voucher`);
        }
        this.dialog.confirm = false
        this.process.run = false
      })
      .catch(() => {
        this.process.run = false
        this.dialog.confirm = false
        // this.$refs.snackbar.open("#D32F2F", `Gagal Reset Voucher`);
      })
    },
    
    // MERCHANT CHANGE
    async change_merchant(item) {
      this.limit = 10;
      await this.fetchCompany()
      this.form = {
        license: item.code,
        company_id: "",
        merchant_id: "",
      };
      this.dialog.change_merchant = true;
    },

    async fetchCompany() {
      this.company = {};
      this.process.run = true;
      await get(`company/v1/superuser/list`, {
        params: {
          limit: 20,
          q: this.search_company,
        },
      }).then((response) => {
        let res = response.data;
        this.company = res.data;
        this.process.run = false;
      });
    },

    async fetchMerchant() {
      let url = "merchant/v1/superuser/list";
      if (this.form.company_id != null) { 
        url = `merchant/v1/superuser/list?company_id=${this.form.company_id}`
      }
      this.process.run = true;
      await get(
        url,
        {
          params: {
            limit: 20,
            q: this.search_merchant,
          },
        }
      ).then((response) => {
        let res = response.data;
        this.merchant = res.data;
        this.process.run = false;
      });
    },

    async changeMerchant () {
      let _self = this;
      _self.process.run = true;
      put(`license/v1/superuser/change-merchant//${this.form.license}`, {
        data: {
          company_id: this.form.company_id,
          merchant_id: this.form.merchant_id,
        },
      }).then((response) => {
        let res = response.data;
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Successfully Change Merchant`);
          this.dialog.change_merchant = false;
          this.form = {
            company_id: "",
            merchant_id: "",
          };
          this.fetch();
        } else {
          this.$refs.snackbar.open("#D32F2F", `Failed to Change Merchant`);
        }
        this.process.run = false;
      }).catch((error) => {
        this.$refs.snackbar.open("#D32F2F", `Failed to Change Merchant`);
        this.process.run = false;
      });
    },

    searchCompany() {
      if (this.search_company != null && this.search_company.length > 2) {
        this.fetchCompany();
      } else if (
        this.search_company != null &&
        this.search_company.length < 1
      ) {
        this.fetchCompany();
      }
    },

    searchMerchant() {
      if (this.search_merchant != null && this.search_merchant.length > 2) {
        this.fetchMerchant();
      } else if (
        this.search_merchant != null &&
        this.search_merchant.length < 1
      ) {
        this.fetchMerchant();
      }
    },
    
    searchMerchant() {
      if (this.search_merchant != null && this.search_merchant.length > 2) {
        this.fetchMerchant();
      } else if (
        this.search_merchant != null &&
        this.search_merchant.length < 1
      ) {
        this.fetchMerchant();
      }
    },
  }
}
</script>

<style>

</style>